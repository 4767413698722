


























































import CommonMixin from "../dynamic_fields/form/mixins/common"
import FieldComponentMixin from './mixins/field_components'
import SubmitButtonMixin from './mixins/submit_button'
import CancelButtonMixin from './mixins/cancel_button'
import AdviseButtonMixin from './mixins/advise_button'
import DraftButtonMixin from './mixins/draft_button'
import ModeViewMixin from './mixins/mode_view'

import GroupFields from '../dynamic_fields/form/group_fields'

import DynamicFields from "../dynamic_fields/libs/dynamic_fields"

export default {
  mixins: [ModeViewMixin, FieldComponentMixin, SubmitButtonMixin, CancelButtonMixin, DraftButtonMixin, AdviseButtonMixin]

  components: {GroupFields}

  props:
    fields: Array
    projectId: [Number, String]
    formNamespace: String
    category: Boolean
    stageAllowAddIdea: Boolean
    groupsAsSteps: Boolean
    useApolloQueryCommon: Boolean
    fieldNamePrefix:
      type: String
      required: true

  computed:
    fieldsForView: ->
      if @isModeEdit && @groupsAsSteps
        fields = []
        @fields.forEach (field) ->
          if field.type == 'group'
            fields.push(...field.fields.getFields())
          else
            fields.push(field)
        fields
      else
        @fields

  methods:
    fieldComponent: (field) ->
      if field.type == 'group'
        "GroupFields"
      else
        @fieldComponentBase(field)

}
