





























import FlattenView from '../../components/forms/flatten_view'
import StepView from '../../components/forms/step_view'

import DynamicFieldsModel from "../../components/forms/mixins/dynamic_fields_model"

import axios from 'axios'

MIXIN_DATA = {
  data: ->
    showForm: false
}

export default {
  mixins: [MIXIN_DATA, DynamicFieldsModel]

  components: {FlattenView, StepView}

  props:
    actionUrl:
      type: String
      default: ''
    projectId:
      type: [String, Number]
      default: null
    formId:
      type: String
      default: 'pim-idea-form'
    formNamespace:
      type: String
      default: ''
    fieldNamePrefix:
      type: String
      default: ->
        ''
    formClass:
      type: String
      default: ''
    crsfParam:
      type: String
      default: ''
    crsfToken:
      type: String
      default: ''
    groupsAsSteps:
      type: Boolean
      default: false
    stageAllowAddIdea:
      type: Boolean
      default: true
    useApolloQueryCommon:
      type: Boolean
      default: false
    mode:
      type: String
      default: 'new'
    dynamicFieldNamespace:
      type: String
      default: ''
    callerElement: {}

  computed:
    viewComponent: ->
      if @groupsAsSteps && @hasFieldsGroups
        'StepView'
      else
        'FlattenView'

    isModeNew: ->
      @mode == 'new'

    isModeEdit: ->
      @mode == 'edit'

    formClassAll: ->
      @formClass

    formMethod: ->
      if @isModeNew
        'post'
      else
        'patch'

  beforeMount: ->
    @beforeMountDynamicFieldsModel()

  beforeDestroy: ->
    @formResetState()

  methods:
    clickCancelButton: ->
      @formResetState()
      @showForm = false

      @$emit('formCancelled')

    clickSubmitButton: ->
      if @$refs.form.checkValidity()
        @childrenSubmitStart()

        axios.request(@axisRequest()).then(@axisSuccess.bind(@))
      else
        @$refs.form.reportValidity()
      
    axisRequest: ->
      url: @actionUrl
      method: 'post'
      data: @formData()

    axisSuccess: (response) ->
      data = response.data
      @resetErrorMessages()
      if data.status? && data.status == 'success'
        @formReset()

        if @callerElement
          if @fieldNamePrefix == 'expert_report'
            window.____expertiseModalSuccessHandler(@callerElement, data)
          else
            window.____ideaGroupReportModalSuccessHandler(@callerElement, data)

        @$emit('formSubmitted')

      else if data.status? && data.status == 'error'
        @assignErrorsToField(data.errors)
        @childrenSubmitFinish()

    formData: ->
      # TODO: надо убрать привязку к jquery. В ней просто удобный сериализатор формы
      data = $(@$refs.form).serializeArray()
      newData = new FormData();
      data.forEach (i) ->
        newData.append(i.name, i.value)
      newData

    formResetState: ->
      @resetErrorMessages()
      @formReset()

    formReset: ->
      @$refs.form.reset()
      @__fieldsModel.resetValueToInitial()
      @inputReset(@$children)

    inputReset: (children) ->
      children.forEach (child) =>
        child.inputReset() if child.inputReset
        @inputReset(child.$children)

    childrenSubmitStart: ->
      @$children.forEach (child) ->
        child.submitStart() if child.submitStart

    childrenSubmitFinish: ->
      @$children.forEach (child) ->
        child.submitFinish() if child.submitFinish

    assignErrorsToField: (errors) ->
      return unless errors?
      Object.keys(errors).forEach (fieldName) =>
        fieldErrorInfo = errors[fieldName]

        if fieldName == 'dynamic_field_values'
          fieldErrorInfo = @assignErrorsToField(fieldErrorInfo)
        else
          @__fieldsModel.setErrorMessages(fieldName, [fieldErrorInfo.messages[fieldName]])

    resetErrorMessages: ->
      @__fieldsModel.resetErrorMessages()
}
