FormDataMixin = {
  data: ->
    actionUrl: ''
    projectId: null
    fields: []
    formId: 'expertise-report-form'
    formNamespace: ''
    formClass: ''
    crsfParam: ''
    crsfToken: ''
    groupsAsSteps: false
    stageAllowAddIdea: true
    mode: 'new'
    dynamicFieldNamespace: 'ExpertReport'
    fieldNamePrefix: ''
    useApolloQueryCommon: false
}

export default FormDataMixin
