



























import MixinDynamicField from "./mixins/dynamic_field"

import FormPartInputGroup from '../../forms/form_part_input_group'

import DynamicFieldInteger from './fields/integer'
import DynamicFieldCollection from './fields/collection'
import DynamicFieldCriterion from './fields/criterion'
import DynamicFieldDictionary from './fields/dictionary'
import DynamicFieldString from './fields/string'
import DynamicFieldImage from './fields/image'
import DynamicFieldFile from './fields/file'
import DynamicFieldUser from './fields/user'

import OtherValuePart from './fields/_other_value'

export default {
  mixins: [MixinDynamicField]

  components: {FormPartInputGroup,
    DynamicFieldInteger, DynamicFieldDictionary,
    DynamicFieldCriterion, DynamicFieldCollection,
    DynamicFieldString, DynamicFieldImage, DynamicFieldFile,
    DynamicFieldUser,
    OtherValuePart}

  computed:
    isShowOtherField: ->
      @field.has_other_value? && @field.has_other_value && @showOtherField

    containerWrapperClass: ->
      {
        'input-group': @field.has_other_value
        'input-group--nested': @field.has_other_value
      }

    showOtherField: ->
      return false unless @fieldValue?
      if Array.isArray(@fieldValue)
        @fieldValue.some((v) -> (v.toString().startsWith('other:')))
      else
        @fieldValue.startsWith('other:')

    isVisible: ->
      @field.isVisible()

  methods:
    dynamicFieldComponent: ->
      if @field.type == 'integer'
        'DynamicFieldInteger'
      else if @field.type == 'collection'
        'DynamicFieldCollection'
      else if @field.type == 'criterion'
        'DynamicFieldCriterion'
      else if @field.type == 'string'
        'DynamicFieldString'
      else if @field.type == 'dictionary'
        'DynamicFieldDictionary'
      else if @field.type == 'image'
        'DynamicFieldImage'
      else if @field.type == 'file'
        'DynamicFieldFile'
      else if @field.type == 'user'
        'DynamicFieldUser'
}
