



















import FormDataMixin from "./mixins/form_data"

import FormExpertReport from './form'

DATA_MIXIN = {
  data: ->
    callerElement: null
}

export default {
  mixins: [FormDataMixin, DATA_MIXIN]

  components: {FormExpertReport}

  methods:
    formSubmittedHandler: ->
      if @callerElement?
        @$destroy()

    formCancelledHandler: ->
      modelContainer = @$el.closest("#expert-report-form")
      modelContainer = @$el.closest("#idea-group-report-form") unless !!modelContainer
      if modelContainer?
        $(modelContainer).foundation('close')
        @$destroy()
}
