






































































import SubmitButtonMixin from './mixins/submit_button'
import CancelButtonMixin from './mixins/cancel_button'
import AdviseButtonMixin from './mixins/advise_button'
import DraftButtonMixin from './mixins/draft_button'
import ModeViewMixin from "./mixins/mode_view"

import DynamicFields from "../dynamic_fields/libs/dynamic_fields"

import Step from './step'

export default {
  mixins: [ModeViewMixin, SubmitButtonMixin, CancelButtonMixin, DraftButtonMixin, AdviseButtonMixin]

  components: {Step}

  props:
    fields: Array
    projectId: [Number, String]
    formNamespace: String
    category: Boolean
    stageAllowAddIdea: Boolean
    useApolloQueryCommon: Boolean
    fieldNamePrefix:
      type: String
      required: true

    dynamicFieldNamespace:
      type: String
      default: ''


  data: ->
    currentStepIndex: 0

  computed:
    __fields: ->
      DynamicFields.build(@fields)

    steps: ->
      @__fields.filter (f) ->
        f.isGroup()

    currentStep: ->
      @steps[@currentStepIndex]

    isShowNextButton: ->
      @currentStepIndex < @steps.length - 1

    isShowPreviousButton: ->
      @currentStepIndex != 0

    isShowSubmitButton: ->
      @currentStepIndex == @steps.length - 1

    disableSubmitButton: ->
      return true if @isSubmit
      if @canSaveDraft
        @steps.some (step) =>
          !@allRequiredFieldsFill(step.fields.getFields())
      else
        @isSubmit

    isDisabledNextButton: ->
      return false if @canSaveDraft

      !@allRequiredFieldsFill(@currentStep.fields.getFields())

  methods:
    clickPreviousButton: ->
      @currentStepIndex -= 1
      @currentStepIndex = 0 if @currentStepIndex < 0

    clickNextButton: ->
      @currentStepIndex += 1

    isStepShow: (stepIndex) ->
      @currentStepIndex == stepIndex

    changeScroll: ->
      formElement = @$el.closest('form')
      return unless formElement?

      formOffset = parseInt(formElement.offsetTop)

      newScrollTop = (formOffset - parseInt($('main.main').css('margin-top')) - parseInt($('header .header').height()))

      if newScrollTop >= 0
        if document.querySelector('html').scrollTo?
          document.querySelector('html').scrollTo(0, newScrollTop)
        else
          document.querySelector('html').scrollTop = newScrollTop

    inputReset: ->
      @currentStepIndex = 0

    allRequiredFieldsFill: (fields) ->
      !fields.some (field) ->
        return false unless field.required
        return true unless field.value?
        if Array.isArray(field.value)
          field.value.length == 0
        else if typeof(field.value) == 'object'
          Object.keys(field.value).length == 0
        else if typeof(field.value) == 'string'
          field.value.trim() == ''
        else
          false

  watch:
    currentStepIndex: ->
      @changeScroll()
}
