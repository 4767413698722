








































  import DynamicFieldMixin from "../mixins/dynamic_field"
  import axios from 'axios'

  import gql from 'graphql-tag'
  import CollectionBoxes from './common/collection_boxes'

  export default {
    components: {CollectionBoxes},
    mixins: [DynamicFieldMixin]

    apollo: {
      dictionaryItems: ->
        query: @apolloQuery()
        variables: ->
          projectId: parseInt(@projectId)
          fieldName: @field.name
          dictionaryId: @field.dictionary_id

        update: (data) ->
          if data.dictionaries? && data.dictionaries.length > 0
            data.dictionaries[0].items
          else if data.project?.ideaField?.dictionary?.items
            data.project.ideaField.dictionary.items
          else
            []
    }

    data: ->
      dictionaryItems: []
      levels: []
      selectedIds: []
      otherValue: ''

    updated: ->
#      $(@$refs.dictionaryItems).styleSelect()

    computed:
      isTree: ->
        @field.dictionary.type == 'Dictionaries::Tree'

      isFlat: ->
        @field.dictionary.type == 'Dictionaries::Flat'

      inputGroupContainerClass: ->
        optional: !@field.required
        required: @field.required


      displayOther: ->
        @isOtherId(@selectedIds[@selectedIds.length - 1])

      initialItem: ->
        return null unless @initialValue && @initialValue.length > 0
        #@initialValue[0]
        if typeof(@initialValue[0]) == 'string' && @initialValue[0].startsWith('other:')
          @initialValue[0]
        else
          @dictionaryItems.find (i) =>
            i.id == @initialValue[0]?.id?.toString() && !i.inactive

      initialItems: ->
        return [] unless @initialValue && @initialValue.length > 0
        self = @
        @initialValue.map (v) ->
          self.dictionaryItems.find (i) =>
            i.id == v?.id?.toString() && !i.inactive

      hasOtherValue: ->
        @field.has_other_value

      arrayType: ->
        true

      multiple: ->
        @field.max_selected > 1

      placeholder: ->
        if @field.placeholder? && @field.placeholder?[@$pageLang()]?.trim() != ''
          @field.placeholder
        else
          @$I18n('not_chosen')

      viewAsSelect: ->
        @field.view_as == 'select'

      itemsForInlineList: ->
        @dictionaryItems.map (dictionaryItem) ->
          {
            label: dictionaryItem.value
            value: dictionaryItem.id
          }

    methods:
      resetInitialValues: ->
        if @multiple
          @resetInitialValuesMultiple()
        else
          @resetInitialValuesSingle()

      resetInitialValuesMultiple: ->
        self = @
        @levels.push(@selectItems(0,null, false))

        @selectedIds.push([])
        @initialItems.forEach (v) ->
          if typeof(v) == 'string' && v.startsWith('other:')
            self.selectedIds[0].push('other:')
            self.otherValue = v.substring('other:'.length)
          else
            self.selectedIds[0].push(v.id) if v?.id

        if @required && !@selectedIds[0].length
          @selectedIds[0].push(@levels[0][0].id)

        @$nextTick ->
          field = @field
          $(@$el).find('select').each ->
            if $(@).css('display') != 'none'
              $(@).select2({ multiple: true, maximumSelectionLength: field.max_selected, placeholder: field.placeholder })

      resetInitialValuesSingle: ->
        field = @field
        if !@initialItem || @initialItem == 'nil'
          @levels.push(@selectItems(0, null))
          @selectedIds.push(@levels[0][0].id)
        else
          if @isOtherId(@initialItem)
            valueParts = @initialItem.split(':')
            #@initialItem = valueParts.pop()
            @otherValue = valueParts.pop()
            level = 0
            if valueParts.length == 1 || (valueParts.length == 2 && (valueParts[1] == 'null' || valueParts[1] == ''))
              parentId = null
            else
              parentId = parseInt(valueParts[1])
              h = (el) ->
                el.id == parentId.toString()
              parent = @dictionaryItems.find(h)
              if parent
                level = parent.level + 1
            @selectedIds.splice(0, 0, valueParts.join(':').concat(':'))
            @initValues(level, parentId)
          else
            @selectedIds.splice(0, 0, @initialItem.id)
            children = @selectItems(@initialItem.level + 1, @initialItem.id)
            if children.length > 1
              @selectedIds.push(@initialItem.id)
              @levels.push(children)
              @levels[0][0].id = @initialItem.id
            @initValues(@initialItem.level,  @initialItem.parentId)
        @$nextTick ->
          $(@$el).find('select').each ->
            if $(@).css('display') != 'none'
              $(@).styleSelect()

      initValues: (level, parentId) ->
        siblingsValues = @selectItems(level, parentId)
        @levels.splice(0, 0, siblingsValues)

        if parentId
          @levels[0][0].id = parentId
          h = (el) ->
            el.id == parentId.toString()
          parent = @dictionaryItems.find(h)
          @selectedIds.splice(0, 0, parent.id)
          @initValues(level - 1, parent.parentId)
        else
          @levels[0][0].id = @defaultValue(0)

      selectItems: (level, parentId, includeNotSelected = true) ->
        result = []
        result = [{position: -2, id: @defaultValue(level), value: @placeholder }] if includeNotSelected

#        if @notdefined && level == 0
#          result = result.concat( {position: -1, id: @notdefined.value, value: @notdefined.text })
        levelItems = @dictionaryItems.filter( (el) ->
          if level == 0
            el.level == level
          else
            el.level == level &&  el.parentId == parentId.toString()
        , @)
        if levelItems.length == 0
          return [{}]
        result = result.concat( levelItems )
        if @hasOtherValue
          if parentId
            other_option_id = 'other:' + parentId + ':'
          else
            other_option_id = 'other:'
          result = result.concat( {position: result.length + 2, id: other_option_id, value: @$I18n('dynamic_fields.dictionary.other_value') } )
        return result.sort (a, b) ->
          a.position - b.position

      changeSelect: (level) ->
        $(@$el).find('select').val(@selectedIds[level])
        if level < @levels.length - 1
          @levels.splice(level + 1, @levels.length - level - 1)
          @selectedIds.splice(level + 1, @selectedIds.length - @levels.length)
        @$nextTick ->
          if !@isOtherId(@selectedIds[level])
            children = @selectItems(level + 1, @selectedIds[level])
            if children.length > 1
              @levels.push(children)
              @selectedIds.push(@selectedIds[@selectedIds.length - 1])
            @$nextTick ->
              @updateSelects()

      defaultValue: (index) ->
        if index > 0
          return @selectedIds[index - 1]
        return @placeholderValue

      updateSelects: ->
        el = $(@$el).find('select:visible')
        if @multiple
          el.select2({ muliple: true, maximumSelectionLength: @field.max_selected, placeholder: @placeholder})
        else
          el.styleSelect()

      parseId: ->
        if @input
          regexp = /id=\\"(\S*)\\"/g
          return regexp.exec(@input)[1]

      parseName: ->
        if @input
          regexp = /name=\\"(\S*)\\"/g
          return regexp.exec(@input)[1]

      resetField: ($el) ->
        if $.contains($el[0], @$el)
          @levels.splice(0, @levels.length)
          @selectedIds.splice(0, @selectedIds.length)
          @resetInitialValues()
          @$nextTick ->
            $(@$el).find('select').change()

      isOtherId: (id) ->
        String.call('join', id).split(':')[0] == 'other'

      otherFieldAttrs: (attr) ->
        attr.replace('[dynamic_field_values]', '[dynamic_field_values][other_values][]').replace('_dynamic_field_values_', '_dynamic_field_values_other_values_')

      apolloQuery: ->
        if @projectId? && @projectId.toString() != '' && !@useApolloQueryCommon
          @apolloQueryProject()
        else
          @apolloQueryCommon()

      apolloQueryProject: ->
        gql """
          query DynamicFieldDictionaryItems($projectId: ID!, $fieldName: String!) {
            project(id:$projectId) {
              ... on Pim__Project {
                ideaField(fieldName: $fieldName) {
                  ... on DynamicFields__Dictionary {
                    dictionary {
                      items(all: true){
                        id
                        value
                        level
                        parentId
                        inactive
                      }
                    }
                  }
                }
              }
             }
           }
          """

      apolloQueryCommon: ->
        gql """
          query DynamicFieldDictionaryItems($dictionaryId: ID!) {
            dictionaries(id:$dictionaryId) {
              items(all: true){
                id
                value
                level
                parentId
                inactive
              }
            }
          }
          """

    watch:
      dictionaryItems: (newItems, oldItems) ->
        @resetInitialValues()

      selectedIds: (newValue, oldValue) ->
        @field.setValue(newValue)
  }
